import React from 'react'
import NavList from './nav-list';
import './styles.css';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    menuBar: true
  };

  showMenuBar() {
    this.setState({ menuBar: !this.state.menuBar });
  }

  render() {
    const { color, active } = this.props;

    return (
      <div>
        {this.state.menuBar ? (
          <NavList hidden={false} color={color} active={active} />
        ) : (
          <NavList hidden={false} color={color} active={active} />
        )}
      </div>
    )
  }
}

export default NavBar
