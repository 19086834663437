import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import Header from "../components/header"
import Footer from "../components/footer"
import favicon from "../assets/images/favicon.png"
import "./index.css"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faEnvelope,
  faHome,
  faBars,
} from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { configureAnchors } from "react-scrollable-anchor"

configureAnchors({ offset: -100, scrollDuration: 800 })

library.add(faPhone)
library.add(faEnvelope)
library.add(faHome)
library.add(faBars)
library.add(fab)

const Layout = ({ children, data }) => (
  <div>
    <Helmet
      title="Moore Advanced"
      meta={[
        {
          name: "description",
          content:
            "Moore Advanced is a staffing company with a focus on logistics, bringing to bear a wide range of primary and value-added services integral to getting your job done and getting it done right.",
        },
        {
          name: "keywords",
          content:
            "lumping, lumpers, VAS, freight, loading, unloading, offloading, shipping, container, railcars, trucks, splitting, warehouse, employment, temp, staffing, industrial, recruitment, workforce, labor, personnel, hire, labels, labeling, logistical, clean-up, recycling, nationwide ",
        },
      ]}
      link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
    />

    <Header />

    <div style={{ marginTop: "133px" }}>{children}</div>

    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
