import React from 'react';
import NavLink from './nav-link';
import './styles.css';

const NavList = ({hidden, color, active}) => {
  let display = '';
  
  if (hidden) display = 'none';
  else display = 'flex';

  return (
    <ul className="nav-list" style={{ display: `${display}` }} >
      <NavLink name="ABOUT" src="/about/" color={color} active={active} />
      <NavLink name="SERVICES" src="/services/" color={color} active={active} />
      <NavLink name="CAREERS" src="/careers/" color={color} active={active} />
      <NavLink name="CONTACT" src="/contact/" color={color} active={active} />
    </ul>
  );
}

export default NavList;