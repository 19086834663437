import React from 'react';
import Link from 'gatsby-link'
import './styles.css';

const NavLink = ({ name, src, color, active }) => (
  <li className="nav-link">
    <Link to={src} className="nav" activeStyle={{color: `${active}`}} style={{
      textDecoration: 'none',
      color: `${color}`
    }}>
      <span className="nav-name">{name}</span>
    </Link>
  </li>
)

export default NavLink;