import React from "react"
import Link from "gatsby-link"
import NavBar from "./navbar/navbar"
import logo from "../assets/images/logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./styles.css"

class Header extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    mobileNav: false,
  }

  showMobileNav() {
    this.setState({ mobileNav: !this.state.mobileNav })
  }

  render() {
    return (
      <div className="site-header">
        <div className="bar-row">
          <Link to="/">
            <img src={logo} style={{ width: "150px", minWidth: "150px" }} />
          </Link>

          <div className="nav-bar">
            <NavBar color="white" active="#aaaaaa" />
          </div>

          <div className="burger-menu">
            <span onClick={() => this.showMobileNav()}>
              <FontAwesomeIcon icon="bars" />
            </span>
          </div>
        </div>

        {this.state.mobileNav ? <MobileNav /> : null}
      </div>
    )
  }
}

const MobileNav = () => (
  <div className="mobile-bar">
    <NavBar color="white" active="#aaaaaa" />
  </div>
)

export default Header
