import React from 'react';
import logo from '../assets/images/logo.png';
import NavBar from '../components/navbar/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';

const Footer = () => (
  <div style={{
    backgroundColor: '#334857',
    padding: '30px 80px',
    color: 'white'
  }}>
    <div className="footer-items">
      <img src={logo} width="100" height="50" className="footer-logo" />

      <div style={{
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
        fontFamily: 'sans-serif',
        alignItems: 'baseline',
        justifyContent: 'space-between'
      }}>

        <div className="footer-nav">
          <NavBar color="white" />
        </div>

        <div className="footer-nav">
          <a style={{textDecoration: 'none', color: 'white'}} href="https://www.facebook.com/mooreadvanced/" target="_blank"><FontAwesomeIcon icon={['fab', 'facebook']} /></a>
          <a style={{textDecoration: 'none', color: 'white'}} href="https://www.linkedin.com/company/moore-advanced-inc/" target="_blank"><FontAwesomeIcon style={{marginLeft: '15px'}} icon={['fab', 'linkedin']} /></a>
          <a style={{textDecoration: 'none', color: 'white'}} href="https://twitter.com/Moore_Advanced" target="_blank"><FontAwesomeIcon style={{marginLeft: '15px'}} icon={['fab', 'twitter']} /></a>
        </div>
      </div>
    </div>
    <div style={{ width: '100%', borderBottom: '1px solid white' }}></div>

    <p style={{ fontSize: '0.7em', padding: '10px', textAlign: 'center', fontFamily: 'FuturaLight', letterSpacing: '0.05em', marginTop: '20px', marginBottom: 0 }}>
      Excellence in service through diversity, respect and partnerships.
    </p>

    <p style={{ fontSize: '0.7em', textAlign: 'center', fontFamily: 'FuturaLight', letterSpacing: '0.05em', margin: 0 }}>
      © Moore Advanced. All Rights Reserved
    </p>
  </div>
);

export default Footer;
